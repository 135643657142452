<template>
  <login></login>
</template>

<script>
import Login from "./../../components/security/login";
import Vue from "vue";

export default {
  beforeRouteEnter(to, from, next) {
    if (Vue.prototype.$auth.check()) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  },
  data: () => ({}),
  components: {
    Login,
  },
};
</script>