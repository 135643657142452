<template>
  <v-app id="login" class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form autocomplete="off" @submit.prevent="login">
              <v-card
                max-width="850"
                elevation="0"
                class="mx-auto py-0"
                rounded="0"
                color="rgba(255,255,255,0)"
              >
                <v-row no-gutters class="shadow-one">
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="third d-flex"
                  >
                    <v-card
                      elevation="0"
                      class="py-0 mx-auto my-auto"
                      rounded="0"
                      max-width="350"
                      color="rgba(255,255,255,0)"
                    >
                      <v-img
                        width="350"
                        :src="require('@/assets/img/login.jpg')"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="white"
                    :sm="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                  >
                    <v-card
                      elevation="0"
                      class="pt-4 mx-auto mb-5"
                      rounded="0"
                      max-width="400"
                    >
                      <v-card-text class="mb-0 pb-2 px-8 text-center">
                        <div class="d-flex justify-center">
                          <div class="mb-5">
                            <v-img
                              :src="require('@/assets/img/logo.png')"
                              width="60"
                            ></v-img>
                          </div>
                        </div>
                        <div class="text-h6 primary--text">
                          <span class="font-weight-bold">{{
                            $t("security.title")
                          }}</span>
                        </div>
                        <div class="text-body-2 grey--text">
                          {{ $t("security.description") }}
                        </div>
                      </v-card-text>
                      <v-card-text class="mb-2 pb-1 px-8">
                        <v-text-field
                          append-icon="mdi-account-circle-outline"
                          name="email"
                          v-model="form.email"
                          :label="$i18n.t('security.fields.email.title')"
                          type="email"
                          autocomplete="off"
                          outlined
                          dense
                          hide-details
                          class="mb-5 font-weight-bold"
                        ></v-text-field>
                        <v-text-field
                          append-icon="mdi-lock-outline"
                          name="password"
                          v-model="form.password"
                          :label="$t('security.fields.password.title')"
                          type="password"
                          autocomplete="off"
                          outlined
                          dense
                          hide-details
                          class="mb-3 font-weight-bold"
                        ></v-text-field>
                        <div class="d-flex align-center justify-end">
                          <div>
                            <router-link
                              :to="{ name: 'forgot-password' }"
                              class="text-right"
                            >
                              <span class="primary--text font-weight-bold">{{
                                $t("security.btn.forgot_password.title")
                              }}</span>
                            </router-link>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions class="px-8 mb-0 align-center">
                        <v-btn
                          class="px-4"
                          type="submit"
                          color="primary"
                          block
                          :loading="loading"
                        >
                          <span class="secondary-font text-subtitle-1">{{
                            $t("security.btn.login.submit")
                          }}</span>
                        </v-btn>
                      </v-card-actions>
                      <v-card-text class="text-center py-0 my-2">
                        <span
                          class="font-weight-bold"
                          style="font-size: 1.05rem"
                        >
                          <span>{{
                            $t("security.btn.register.description")
                          }}</span>
                          <router-link
                            class="ml-2"
                            text
                            :to="$i18n.getRouteLink('register')"
                            >{{
                              $t("security.btn.register.title")
                            }}</router-link
                          >
                        </span>
                      </v-card-text>
                      <v-card-actions class="mb-2 pb-0 mt-8 px-0">
                        <v-spacer></v-spacer>
                        <lang :btnOptions="{'x-small': true}"></lang>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                max-width="340"
                elevation="0"
                class="py-0 my-0 mx-auto"
                color="rgba(255,255,255,0)"
              >
                <v-card-text class="text-center py-3 my-0">
                  <span class="grey--text text--darken-1 text-body-2"
                    >&copy; LMTGROUP 2021</span
                  >
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Lang from './../commons/lang';
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    form: {
      email: null,
      password: null,
      remember: false,
    },
    loading: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    async login() {
      const sessionRedirectPath = sessionStorage.getItem("_redirect_path");

      let redirect = sessionRedirectPath
        ? { path: sessionRedirectPath }
        : this.$auth.redirect();
      let message = this.$t("security.error.unauthorized");

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: message, centered: true, right: false });
        return;
      }

      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");

        await this.$auth.login({
          data: {
            email: this.form.email,
            password: this.form.password,
          },
          headers: {
            recaptcha: token,
          },
          ignoreVueAuth: true,
          redirect: redirect ? redirect : { name: "dashboard" },
        });
      } catch (error) {
        const status = error.response ? error.response.status : 500;

        if (status !== 401) {
          message = this.$t("error_occured");
        }
        this.notify({ message: message, centered: true, right: false });
      }

      this.loading = false;
    },
    ...mapActions({ notify: "notification/notify" }),
  },
  metaInfo() {
    return {
      title: this.$i18n.t("security.meta"),
    };
  },
  components: {
    Lang
  },
};
</script>